import React from "react";
import {Button, Col, Dropdown, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setChartCoin} from "../../../../actions/gameCoin";
import {__, encode, forceSatoshiFormat, wait, COIN_TOKEN} from "../../../../Helper";
import Chart from "../Chart";
import coins from "../../../coins";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import PaymentIcon from '@mui/icons-material/Payment';

class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            showChart: false,
            openedChart: false,
            changeCoinLoader: false,
            medalView: false,
            coins: [],
            currentCoin: COIN_TOKEN
        };
    }

    componentWillMount() {
        coins.forEach((item, key) => {
            let coin = <Dropdown.Item key={key} onClick={e => this.selectCoin(item.preffix)}
                                      className={'animated slideInUp'}>
                <span key={key} className={'dropdown-item'}>
                    <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-8'}/>
                    {item.preffix}
                </span>
            </Dropdown.Item>;
            this.setState(state => ({coins: [coin, ...state.coins]}));
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        wait(100).then(() => {
            if (nextProps.haveData === 'no') {
                this.setState({loader: true})
            } else {
                let props = nextProps;
                let dd = props.data;
                let chart = <Chart token={props.token} name={props.name}/>;
                let since = sinceConvert(dd.created);
                let color = dd.profit < 0 ? 'text-danger' : 'text-success';
                let coin = dd.coin;
                let level = dd.level;
                let name = props.name;
                let highProfit = dd.high_profit;
                let lowProfit = dd.low_profit;

                this.setState({
                    changeCoinLoader: false,
                    since: since, lowProfit: lowProfit, highProfit: highProfit,
                    name: name, coin: coin, currentCoin: coin, level: level,
                    color: color, chart: chart, avatar: dd.avatar, profit: dd.profit,
                    date: dd.created, played: dd.played, wined: dd.wined, medal: dd.medal, _token: props.token
                })

                wait(200).then(() => {
                    if (!__.isEmpty(name) || !__.isUndefined(name))
                        this.setState({loader: false})
                })
            }
        })
    }

    componentDidMount() {
        if (this.props.haveData === 'no') {
            this.setState({loader: true})
        } else {
            let props = this.props;
            let dd = props.data;
            let chart = <Chart token={props.token} name={props.name}/>;
            let since = sinceConvert(dd.created);
            let color = dd.profit < 0 ? 'text-danger' : 'text-success';
            let coin = dd.coin;
            let level = dd.level;
            let name = props.name;
            let highProfit = dd.high_profit;
            let lowProfit = dd.low_profit;

            this.setState({
                since: since,
                lowProfit: lowProfit,
                highProfit: highProfit,
                currentCoin: coin,
                name: name,
                coin: coin,
                level: level,
                color: color,
                chart: chart,
                avatar: dd.avatar,
                profit: dd.profit,
                date: dd.created,
                played: dd.played,
                wined: dd.wined,
                medal: dd.medal,
                _token: props.token
            })

            wait(200).then(() => {
                if (!__.isEmpty(name) || !__.isUndefined(name))
                    this.setState({loader: false})
            })
        }
    }

    componentWillUnmount() {
        this.props.setChartCoin(this.state.currentCoin);
    }

    selectCoin(coin) {
        //Set For Redux
        this.props.setChartCoin(coin);
        this.setState({currentCoin: coin, changeCoinLoader: true});
        socket.emit(C.USER_INFO, encode({name: this.props.name, coin: coin, page: 1}));
    }

    getChart(game) {
        wait(100).then(() => {
            if (document.querySelector("#userChart") !== null)
                document.querySelector("#userChart").innerText = "[ Please Wait... ]";
        })

        socket.emit(C.USER_CHART, encode({name: this.props.name, coin: this.state.currentCoin, game: game}));
        this.setState({openedChart: true});
    }

    back = e => {
        this.setState({openedChart: false});
    }

    showChart = e => {
        this.setState({showChart: !this.state.showChart});
        this.getChart('crash');
    }

    toggleMedalView = () => {
        this.setState({medalView: !this.state.medalView});
    }

    render() {
        let {currentCoin, lowProfit, highProfit, name, coin, color, chart, since, avatar, profit, date, _token, level, played, wined, medal} = this.state;

        if (!__.isUndefined(date))
            date = date.substr(0, 10);

        played = played ? played : null;
        wined = wined ? wined : 0;

        medal = parseInt(medal);

        return (
            <>
                {this.state.loader ?
                    <>
                        <div className="text-center" style={{minHeight: 500}}>
                            <div className="spinner-border text-info my-5 user-loader" role="status"/>
                        </div>
                    </>
                    :
                    <>
                        {
                            this.state.medalView ?
                                <div className={"animated fadeIn medals-view"}>

                                    <div className="media mb-2">
                                        <img src="/assets/images/badges/badge-2.svg" height="60" className="mr-3"/>
                                        <div className="media-body align-self-center">
                                            <div className="mb-2">
                                                <span className="badge badge-purple px-3">Deposit</span>
                                            </div>
                                            Required Minimum 10,000 KES Deposit
                                        </div>
                                    </div>

                                    <div className="media mb-2">
                                        <img src="/assets/images/badges/badge-3.svg" height="60" className="mr-3"/>
                                        <div className="media-body align-self-center">
                                            <div className="mb-2">
                                                <span className="badge badge-purple px-3">Deposit</span>
                                            </div>
                                            Required Minimum 50,000 KES Deposit
                                        </div>
                                    </div>

                                    <div className="media mb-2">
                                        <img src="/assets/images/badges/badge-7.svg" height="45" className="mr-3"/>
                                        <div className="media-body align-self-center">
                                            <div className="mb-2">
                                                <span className="badge badge-purple px-3">Deposit</span>
                                            </div>
                                            Required Minimum 200,000 KES Deposit
                                        </div>
                                    </div>

                                    <div className="media mb-2">
                                        <img src="/assets/images/badges/badge-6.svg" height="60" className="mr-3"/>
                                        <div className="media-body align-self-center">
                                            <div className="mb-2">
                                                <span className="badge badge-purple px-3">Rain</span>
                                            </div>
                                            Required 10 Times Rain
                                        </div>
                                    </div>

                                    <div className="media my-2">
                                        <img src="/assets/images/badges/badge-8.svg" height="60" className="mr-3"/>
                                        <div className="media-body align-self-center">
                                            <div className="mb-2">
                                                <span className="badge badge-purple px-3">Profit</span>
                                            </div>
                                            Required 250,000 KES Profit
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button onClick={this.toggleMedalView}
                                                className={'btn btn-sm btn-cs-6 text-white my-1'}>
                                            Back
                                        </button>
                                    </div>

                                </div>
                                :
                                <div className={"animated fadeIn"}>
                                    <div className="row user-detail mb-1">
                                        <div className={'col-6'}>
                                            Name:<br/>
                                            <div className={'mb-0 text-success uname'}>{name}</div>
                                        </div>
                                        <div className={'col-6 border-left'}>
                                            Duration:<br/>
                                            <div className={"text-nowrap num-style text-success"}>{date}&nbsp;({since})</div>
                                        </div>

                                    </div>

                                    <div className="details-parent text-center">

                                        <Row>
                                            <Col lg="12" md="12" className="col-12">
                                                <div className={'mb-1 my-gradient'}>
                                                    <p className={'text-warning m-1'}>All Time Profit</p>
                                                    {this.state.changeCoinLoader ?
                                                        <div className="text-center">
                                                            <div className="spinner-border spinner-border-sm"
                                                                 role="status"/>
                                                        </div>
                                                        :
                                                        <>
                                                            <PaymentIcon className={"mini-coin-9"}/>&nbsp;
                                                            <b className={'num-style text-success'}>{forceSatoshiFormat(highProfit)} {currentCoin}</b>
                                                        </>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="6" md="6" className="col-6">
                                                {
                                                    played !== null &&
                                                    <>
                                                        <div className={'mb-1 my-gradient'}>
                                                            <p className={'text-warning m-1'}>Total Won</p>
                                                            <b className="num-style text-success">{forceSatoshiFormat(wined)}</b>
                                                        </div>
                                                    </>
                                                }
                                            </Col>
                                            <Col lg="6" md="6" className="col-6">
                                                {
                                                    played !== null &&
                                                    <>
                                                        <div className={'mb-1 my-gradient'}>
                                                            <p className={'text-warning m-1'}>Total Bets</p>
                                                            <b className="num-style text-success">{forceSatoshiFormat(played)}</b>
                                                        </div>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </div>

                                    <Row>
                                        <Col sm={12}>
                                            {this.state.loader ?
                                                <>
                                                    <div className="text-center">
                                                        <div className="spinner-border text-info my-3" role="status"/>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className={'animated slideI8nDown my-1'}>
                                                        <ul className={'list-inline ico-socials text-center mb-0'}>
                                                            {this.state.loader ?
                                                                <>
                                                                    <div className="text-center">
                                                                        <div className="spinner-border text-info my-3" role="status"/>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {!this.state.openedChart ?
                                                                        <Button variant="button" className={'btn btn-block custom-button'} eventKey="0" onClick={this.showChart}>
                                                                            Show Graph
                                                                        </Button>
                                                                        :
                                                                        <>
                                                                            <div id={'userChart'}/>
                                                                            <div id="loadUserChart"/>
                                                                            {chart}
                                                                            <button onClick={this.back} className="btn btn-xs btn-outline-light mb-1 mt-2">
                                                                                Back
                                                                            </button>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </ul>
                                                    </div>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                        }
                    </>
                }
            </>
        );
    }
}

function sinceConvert(time, lang) {
    if (time === undefined) return;
    lang = lang || {
        postfixes: {
            '<': ' ago',
            '>': ' from now'
        },
        1000: {
            singular: 'a few moments',
            plural: 'a few moments'
        },
        60000: {
            singular: 'about a minute',
            plural: '# minutes'
        },
        3600000: {
            singular: 'about an hour',
            plural: '# hours'
        },
        86400000: {
            singular: 'a day',
            plural: '# days'
        },
        31540000000: {
            singular: 'a year',
            plural: '# years'
        }
    };

    let timespans = [1000, 60000, 3600000, 86400000, 31540000000];
    let parsedTime = Date.parse(time.replace(/\-00:?00$/, ''));

    if (parsedTime && Date.now) {
        let timeAgo = parsedTime - Date.now();
        let diff = Math.abs(timeAgo);
        let postfix = lang.postfixes[(timeAgo < 0) ? '<' : '>'];
        let timespan = timespans[0];

        for (let i = 1; i < timespans.length; i++) {
            if (diff > timespans[i]) {
                timespan = timespans[i];
            }
        }

        let n = Math.round(diff / timespan);

        return lang[timespan][n > 1 ? 'plural' : 'singular']
            .replace('#', n) + postfix;
    }
}

Details.propTypes = {
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, {setChartCoin})(Details);