import React from "react";
import storage from "../../../Storage";
import socket from "../../../Socket";
import {__, encode, wait, sendNotification, decode, formatAmount, COIN_TOKEN} from "../../../Helper";
import C from "../../../Constant";
import Engine from "../../Games/Crash/Engine";

class WithdrawBonus extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            loading: true,
            phone: storage.getKey('phone'),
            name: storage.getKey('name'),
            amount: 0,
            withdraw_to: 'wallet',
            with_to: 'Wallet',
            wait_message: 'Please wait...',
            bonus: 0,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        socket.on('BONUS_WITHDRAW', data => this.withdrawalResponse(decode(data)));
        Engine.trigger.on("update_bonus", (data) => this.updateBonus(data));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateBonus(data) {
        this.setState({bonus: data});
    }

    handleInputChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        switch (name) {
            case 'amount':
                this.setState({amount: value});
                break;
            case 'withdraw_to':
                this.setState({withdraw_to: value});
                let w_to = (value === 'wallet') ? 'Wallet' : 'Provider';
                this.setState({with_to: w_to});
                break;
            default:
                break;
        }
    }

    //submit
    submit = (e) => {
        e.preventDefault();

        socket.emit('BONUS_WITHDRAW', encode({
            token: this.state.token,
            coin: COIN_TOKEN,
            withdraw_to: this.state.with_to,
            amount: this.state.amount,
        }));

        wait(600).then(() => {
            this.setState({submitted: true});
        })
    }

    //response
    withdrawalResponse(data) {
        if (this._isMounted) {
            wait(1000).then(() => {
                if (!data) {
                    sendNotification('Request failed!', 'danger', 'top-center');
                    return;
                }

                let message = __.toString(data.status);
                let reason = data.reason; //error or success

                sendNotification(message, 'danger', 'top-center');
                this.setState({wait_message: message});

                if(reason === 'success') {
                    socket.emit(C.CREDIT, encode({token: this.state.token}));

                    this.setState({loading: false, submitted: true});
                    wait(3000).then(() => {
                        this.setState({submitted: false, wait_message: 'Please wait...'});
                    });
                } else {
                    wait(1500).then(() => {
                        this.setState({loading: false, submitted: false, wait_message: 'Please wait...'});
                    });
                }
            });
        }
    }

    render() {
        let {with_to, wait_message, bonus} = this.state;

        return (
            <>
                <div className={"row"}>
                    <div className={"col-sm-12 card m-3 text-center"}>
                        <h5 className={'text-warning'}>Redeem Your Bonus</h5>
                        <p>Select to Redeem either to your Wallet or your Payment Provider</p>
                        <h5 className={'text-white'}>Bonus Balance: <b>{formatAmount(bonus)}</b></h5>
                        <div className="form-group">
                            {
                                this.state.submitted ?
                                    <span className="text-white"><div className="spinner-border spinner-border-sm text-white" role="status"/> {wait_message}</span>
                                    :
                                    <form onSubmit={(e) => {this.submit(e)}}>
                                        <div className={"input-group mb-1"}>
                                            <select style={{border: '3px solid white', borderRadius: '4px 0 0 4px', backgroundColor: 'bisque'}} className={"input-group-append"} name={"withdraw_to"} value={this.state.withdraw_to} onChange={this.handleInputChange}>
                                                <option value="wallet">To Wallet</option>
                                                <option value="lime">To Provider</option>
                                            </select>
                                            <input placeholder={"Enter amount e.g " + formatAmount(bonus)} type="text" name={'amount'} className={'form-control'} style={{background: "white", color: 'black'}} onChange={this.handleInputChange} required={true} autoComplete={'off'}/>
                                        </div>

                                        <button type="submit" className="btn btn-success-2 btn-block btn-md btn-block no-shadow"><i className={"fa fa fa-shopping-cart"}></i> Redeem to <strong>{with_to}</strong></button>
                                    </form>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default WithdrawBonus;