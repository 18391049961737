import React from "react";
import {Dropdown} from "react-bootstrap";
import storage from "../../../../Storage";
import coins from "../../../coins";
import socket from "../../../../Socket";
import {__, isMobile, encode, decode, getElement, wait, forceSatoshiFormat, sendNotification, Event, COIN_TOKEN, formatAmount, PAYBILL} from "../../../../Helper";
import DepositArchive from "./DepositArchive";
import C from "../../../../Constant";
import Cookies from "js-cookie";
import {Stack} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Button from "@mui/material/Button";
import PaymentsIcon from "@mui/icons-material/Payments";
import Typography from "@mui/material/Typography";

class CreditsHeader extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            uid: Cookies.get('uid'),
            currentCoin: COIN_TOKEN,
            loading: true,
            credits: {},
            content: [],
            deposit: true,
            slide: false,
            amount: 100,
            phone: storage.getKey('phone'),
            name: storage.getKey('name')
        };
    }

    componentDidMount() {
        this._isMounted = true;
        socket.emit(C.CREDIT, encode({token: this.state.token, coin: this.state.currentCoin}));
        socket.on(C.CREDIT, data => this.getCreditAndCoins(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getCreditAndCoins = (data) => {
        if (this._isMounted) {
            let {credit} = data;
            this.setState({credits: credit, loading: false});
        }
    };

    render() {
        let {loading, currentCoin} = this.state;
        let demo_credit = parseFloat(this.state.credits['demo']).toFixed(2);
        let bonus_credit = parseFloat(this.state.credits['bonus']).toFixed(2);

        return (
            <div className="row text-center">
                <div className={'col-4 p-0'}>
                    <div className={"my-gradient mr-1 border-right"}>
                        <span className={'text-warning'}>Balance</span><br/>
                        <span className="text-white font-15">{formatAmount(this.state.credits[currentCoin])}</span>
                    </div>
                </div>
                <div className={'col-4 p-0'}>
                    <div className={'my-gradient mr-1 border-right'}>
                        <span className={'text-warning'}>Withdrawable</span><br/>
                        <span className="text-white font-15">{formatAmount(this.state.credits[currentCoin])}</span><br/>
                    </div>
                </div>
                <div className={'col-4 p-0'}>
                    <div className={'my-gradient'}>
                        <span className={'text-warning'}>Bonus</span><br/>
                        <span className="text-white font-15">{formatAmount(bonus_credit)}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreditsHeader;