import React from 'react'
import {Helmet} from 'react-helmet'
import { Table } from "react-bootstrap";
import socket from "../../../Socket";
import {BRAND, COIN_CURRENCY, COIN_TOKEN, formatAmount} from "../../../Helper";
import C from "../../../Constant";
import UserModal from "../../Components/User/Stat/Modal";

class Leaderboard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            players: []
        }
    }

    componentDidMount() {
        socket.emit(C.TOP_WINNERS);
        socket.on(C.TOP_WINNERS, data => this.makeList(data));
    }

    makeList = (data) => {
        
        data = data.filter((item, pos, self) => self.findIndex(v => v.name === item.name) === pos);
        this.setState({ loading: false, players: (data) })
    };

    render(){
        const { t } = this.props;

        const list = this.state.players.map((player, i) =>
            <Players key={i} medal={i+1} player={player} />
        );

        return(
                <>
                <Helmet>
                    <title>{BRAND} - Leaderboard</title>
                </Helmet>
                <div className={'table-responsive last-bets num-style mb-0'}>
                    {
                        this.state.loading ?
                            <div className="text-center">
                                <div class="spinner-grow text-white my-3" role="status"/>
                            </div>
                        :
                        <Table className={"mb-2"}>
                            <thead>
                            <tr>
                                <th>
                                   #
                                </th>
                                <th>
                                    Player
                                </th>
                                <th>
                                    All Time Profit
                                </th>
                                <th>
                                    Plays
                                </th>
                                <th>
                                   Medal
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {list}
                            </tbody>
                    </Table>
                    }
                </div>
                </>
        );
    }
}

class Players extends React.Component{
    constructor(props){
        super(props);
    }
    render() {
        let { player, medal } = this.props;
        let num = medal;
        
        if(medal === 10){
            medal = 1
        }

        let coin = COIN_TOKEN;
        let username = player.name;
        let avatar = player.avatar;
        let profit_high = formatAmount(parseFloat(player.profit_high[coin]).toFixed(2));
        let plays = player.plays;

        return(
            <tr className={'q-crash2'}>
                <td>
                    {num}
                </td>
                <td>
                    <img className="rounded thumb-xxs mr-1" src={avatar} />
                    <UserModal username={username} isWinner={false} />
                </td>
                <td className={'num-style text-success text-bold'}>
                    <img src={'/assets/images/' + (coin) + '.png'} className={'mini-coin-2 hidden-sm'} alt={coin}/>
                    {COIN_CURRENCY} {profit_high}
                </td>
                <td className={'num-style text-white'}>
                    <img src={'/assets/images/' + (coin) + '.png'} className={'mini-coin-2 hidden-sm'} alt={coin}/>
                    {plays}
                </td>
                <td>
                    <img className="rounded-circle thumb-xxs ffi" src={'/assets/images/badges/badge-' + medal + '.svg'} />
                </td>
            </tr>
        );
    }
}

export default Leaderboard;