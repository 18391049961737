import React from "react";
import {Dropdown} from "react-bootstrap";
import storage from "../../../../Storage";
import coins from "../../../coins";
import socket from "../../../../Socket";
import {__, isMobile, encode, decode, getElement, wait, sendNotification, COIN_TOKEN, PAYBILL} from "../../../../Helper";
import C from "../../../../Constant";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";

class Confirm extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            uid: Cookies.get('uid'),
            currentCoin: COIN_TOKEN,
            loading: true,
            list: [],
            content: [],
            wallet: 'Please Wait...',
            deposit: true,
            slide: false,
            height: 573,
            margin: 'mt-1',
            phone: storage.getKey('phone'),
            name: storage.getKey('name')
        };
    }

    componentDidMount() {
        this._isMounted = true;
        socket.emit(C.CREDIT, encode({token: this.state.token, coin: this.state.currentCoin}));
        socket.on(C.CREDIT, data => this.getCreditAndCoins(decode(data)));

        wait(400).then(() => {
            this.loadAddress(true, this.state.currentCoin);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    paymentUpdate(data) {
        if (this._isMounted) {
            let {token, uid} = this.state;

            if (uid !== null) {
                if (parseFloat(data.uid) === parseFloat(uid)) {
                    sendNotification(data.message, 'success', 'top-right');
                    this.setState({submitted: false});
                }
            }
        }
    }

    getCreditAndCoins = (data) => {
        if (this._isMounted) {
            let {credit} = data;
            this.setState({credits: credit, loading: false});
            this.setUpCoins();
        }
    };

    setUpCoins = () => {
        if (isMobile()) {
            this.setState({height: 685, margin: 'mt-1'});
        }

        coins.forEach((item, key) => {
            let list = <Dropdown.Item as={'button'} className={"animated fadeIn"} onClick={(e) => this.loadAddress(item.active, item.preffix)}>
                <span><img src={"/assets/images/" + item.image} className={"img-fluid mini-coin mr-1"} alt="Coin"/> {item.preffix}</span>
            </Dropdown.Item>;
            this.setState(state => ({list: [list, ...state.list]}));
        });
    };

    showArchive = e => {
        this.setState({slide: !this.state.slide});
        wait(500).then(() => {
            this.setState({deposit: !this.state.deposit});
        })
        wait(500).then(() => {
            this.loadAddress(true, this.state.currentCoin);
        })
    };

    loadAddress(active, coin) {
        if (this._isMounted) {
            if (active)
                socket.emit(C.GET_ADDRESS, encode({coin: this.state.currentCoin, token: this.state.token}));
            else {
                getElement('#' + __.upperCase(coin)).value = `${coin} not Supported yet`;
            }
        }
    }

    submit = (e) => {
        e.preventDefault();

        socket.emit(C.CONFIRM, encode({
            phone: this.state.phone,
            mpesa_code: this.state.mpesa_code,
        }));

        wait(600).then(() => {
            this.setState({submitted: true});
        })
    }

    handleChangeConfirm = (e) => {
        let val = e.target.value;
        this.setState({mpesa_code: val});
    }

    render() {
        return (
            <>
                <form onSubmit={(e) => {this.submit(e)}}>
                    <Stack>
                        <input type={"hidden"} value={this.state.phone} name={"phone"}/>
                        <div className={'custom-card m-2'}>
                            <span className={'text-white custom-label'}>CODE</span>
                            <input required placeholder={'-- RD56FR9UG0 --'} type={"text"} id={"mpesa_code"} value={this.state.mpesa_code} onChange={this.handleChangeConfirm}
                                   className={"text-strong form-control custom-input"} autoComplete={'off'} name={"mpesa_code"}/>
                            <StickyNote2Icon className={'text-white'}/>
                        </div>
                        <Button type={'submit'} size={'large'} variant="contained" className={'custom-button m-2'} sx={{m: 2}} disabled={false}><TaskAltIcon/>&nbsp;Verify Payment</Button>
                        {this.state.submitted ?
                            <span style={{fontSize: '.8rem'}} className="text-success text-center"><div className="spinner-border spinner-border-sm" role="status"/>
                                &nbsp;Please wait as we fetch your Transaction</span>
                            : ''}

                        <Typography className={'m-2'} sx={{color: 'white'}} gutterBottom>
                            Copy or type the payment code e.g FFT6FR9UG0 you received on SMS from the Payment Provider then click <b>Verify Payment</b>.
                        </Typography>
                    </Stack>
                </form>
            </>
        );
    }
}

export default Confirm;